import React from 'react'
import { useRef,useEffect } from 'react';
import transicionBottomTop from '../Transicion/transicionBottomTop';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contato = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('Sherlock_mail', 'template_sherlock', e.target, 'u9v4LNWbh7M1__aLT')
            .then((result) => {
                console.log(result.text);
                toast.success('O e-mail foi enviado com sucesso!');
            }, (error) => {
                console.log(error.text);
                toast.error('Não foi possível enviar o e-mail.');
            });

        e.target.reset();
    }

    return (
        <main className='contacto-container'>
            <ToastContainer />
            <div className="container-xxl seccion-grande d-flex flex-wrap justify-content-around">
                <div className="col-11 col-lg-6 py-5">
                    <h3 className='fs-5'>Orçamento</h3>
                    <h1 className='poppins fs-1 fw-semibold mb-4'>Orçamento</h1>
                    <p className='fs-5'>Agradecemos o seu interesse, a sua proposta será enviada de imediato.</p>
                    <p className='fs-5'></p>
                    <div>
                        <div className='d-flex item gap-4'>
                            <div className="icono">
                                <svg width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2c.247 0 .484 .045 .702 .127" />
                                    <path d="M19 12h2l-9 -9l-9 9h2v7a2 2 0 0 0 2 2h5" />
                                    <path d="M16 22l5 -5" />
                                    <path d="M21 21.5v-4.5h-4.5" />
                                </svg>
                            </div>
                            <div className="contenido">
                                <span className='poppins'>Nossa localização</span>
                                <p>Rua Nossa Sra. Operária 216, Vila Guilherme, São Paulo.SP.</p>
                            </div>
                        </div>
                        <div className='d-flex item gap-4'>
                            <div className="icono">
                                <svg width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M4 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M18 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M11 3h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M4 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M18 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M11 10h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                    <path d="M11 17h2a1 1 0 0 1 1 1v2a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-2a1 1 0 0 1 1 -1z" />
                                </svg>
                            </div>
                            <div className="contenido">
                                <span className='poppins'>Telefone</span>
                                <a href="tel:+1191230-0361" className='poppins my-0'>+1191230-0361</a>
                            </div>
                        </div>
                        <div className='d-flex item gap-4'>
                            <div className="icono">
                                <svg width="40" height="40" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000000" fill="none">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                                    <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5" />
                                    <path d="M3 6l9 6l9 -6" />
                                    <path d="M15 18h6" />
                                    <path d="M18 15l3 3l-3 3" />
                                </svg>
                            </div>
                            <div className="contenido">
                                <span className='poppins'>E-mail</span>
                                <a href="https://mail.google.com/mail/?view=cm&to=comercial@sherlock.com.br" target='_blank'>comercial@sherlock.com.br</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg-5 py-5 contacto-form">
                    <form className='contact-form pt-4' onSubmit={(e)=>{
                                sendEmail(e)
                            }}>
                            <div className="row mb-3 px-4">
                                <div className="col">
                                    <label htmlFor="from_name" className="form-label">Nome</label>
                                    <input type="text" className="form-control bg-light" id="from_name" name="from_name" />
                                </div>
                                <div className="col">
                                    <label htmlFor="from_surname" className="form-label">Sobrenome</label>
                                    <input type="text" className="form-control bg-light" id="from_surname" name="from_surname" />
                                </div>
                            </div>
                            <div className="mb-3 px-4">
                                <label htmlFor="from_mail" className="form-label">Email</label>
                                <input type="email" className="form-control bg-light" id="from_mail" name="from_mail" />
                            </div>
                            <div className="mb-3 px-4">
                                <label htmlFor="from_phone" className="form-label">Telefone</label>
                                <input type="tel" className="form-control bg-light" id="from_phone" name="from_phone" />
                            </div>
                            <div className="mb-3 px-4">
                                <label htmlFor="message" className="form-label">Mensagem</label>
                                <textarea className="form-control bg-light" id="message" name="message" rows="4"></textarea>
                            </div>
                            <button type="submit" className="poppins fw-semibold mx-4">Enviar mensagem</button>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Contato)