import React, { useEffect, useRef } from 'react'
import sherlocktr4 from '../Imagenes/produtos/sherlock-tr4.webp'
import lacrestripletrava from '../Imagenes/produtos/lacres-tripla-trava.webp'
import duplaanchora from '../Imagenes/produtos/dupla-anchora.webp'
import lacrebreakaway from '../Imagenes/produtos/lacre-breakaway.webp'
import oneseal from '../Imagenes/produtos/one-seal.webp'
import pb25 from '../Imagenes/produtos/pb-25.webp'
import securlock1 from '../Imagenes/produtos/securlock1.webp'
import securlock2 from '../Imagenes/produtos/securlock2.webp'
import sherlock3 from '../Imagenes/produtos/sherlock-3.webp'
import sherlock44_1 from '../Imagenes/produtos/sherlock44-1.webp'
import sherlock44_2 from '../Imagenes/produtos/sherlock44-2.webp'
import sherlock44_3 from '../Imagenes/produtos/sherlock44-3.webp'
import sherlock45_1 from '../Imagenes/produtos/sherlock45-1.webp'
import sherlock45_2 from '../Imagenes/produtos/sherlock45-2.webp'
import sherlock47 from '../Imagenes/produtos/sherlock47.webp'
import cadeadoblindado from '../Imagenes/produtos/cadeado-blindado.webp'
import utilidades from '../Imagenes/produtos/utilidades.jpg'
import mediciones from '../Imagenes/produtos/medidas-ilustracion.webp'
import { Link } from 'react-router-dom'
import transicionBottomTop from '../Transicion/transicionBottomTop'

const Produtos = () => {
    const isMountedRef = useRef(false);
    useEffect(() => {
        if (!isMountedRef.current) {
            window.scroll(0, 0);
            isMountedRef.current = true;
        }
    }, []);
    const produtos = [
        {
            imagenuno: sherlock44_1,
            imagendos: sherlock44_2,
            imagentres: sherlock44_3,
            titulo: "sherlock-44",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },
	{
            imagenuno: sherlock44_1,
            imagendos: sherlock44_2,
            imagentres: sherlock44_3,
            titulo: "sherlock-40",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: sherlocktr4,
            titulo: "sherlock-tr4",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: lacrestripletrava,
            titulo: "lacres-triple-trava",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: duplaanchora,
            titulo: "dupla-anchora",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: lacrebreakaway,
            titulo: "lacre-breakaway",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: oneseal,
            titulo: "one-seal",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: pb25,
            titulo: "pb-25",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: securlock1,
            titulo: "securlock-I",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: securlock2,
            titulo: "securlock-II",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: sherlock3,
            titulo: "sherlock-III",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: sherlock45_1,
            imagendos: sherlock45_2,
            titulo: "sherlock-45",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: sherlock47,
            titulo: "sherlock-47",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        },{
            imagenuno: cadeadoblindado,
            titulo: "cadeado-blindado",
            parrafouno: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit velit molestiae minus neque itaque maxime eaque enim? Temporibus, quo quasi.",
            parrafodos: "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.",
            colores: ["red","green","blue","yellow"]
        }
    ]
    return (
        <main>
            <div className="container-xxl seccion-grande produtos-container">
                <div className="row justify-content-center">
                    <div className="row items-largos col-12 justify-content-around py-5">
                        <div className="item-largo">
                            <img src={mediciones} alt="" />
                            <Link to="/Produtos/medida">
                                <div className="overlay">
                                    <p className='poppins my-0'>POR MEDIDA</p>
                                </div>
                            </Link>
                        </div>
                        <div className="item-largo">
                            <img src={utilidades} alt="" />
                            <Link to="/Produtos/utilidade">
                                <div className="overlay">
                                    <p className='poppins my-0'>POR UTILIDADE</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="row items col-12 flex-wrap justify-content-center py-5 gap-4 gap-md-5">
                        {
                            produtos.map(produto=>{
                                return (
                                    <Link to={`/Produtos/${produto.titulo}`} key={produto.id} className='item poppins fw-semibold'>
                                            <img src={produto.imagenuno} alt={produto.titulo} />
                                            <div className="overlay">
                                                <p className='poppins fs-2 px-2 fw-semibold'>{produto.titulo}</p>
                                                <button>+ INFO</button>
                                            </div>
                                    </Link>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </main>
    )
}

export default transicionBottomTop(Produtos)